body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f7;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
div::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important; /* Remove scrollbar space */
}
@font-face {
  font-family: "MaterialCommunityIcons";
  src: local("MaterialCommunityIcons"),
    url("./fonts/MaterialCommunityIcons.ttf") format("truetype");
  font-weight: normal;
}
input:focus-visible {
  outline: none !important;
}
